import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
      transform: translateY(250px);
      opacity: 0;
  }
  100% {
      transform: translateY(50px);
      opacity: 1;
  }
`

 const Text = styled.div`
  position: absolute;
  z-index: 0;
  left: 0; 
  width: 100%;
  margin: auto;
  text-transform: uppercase;
  font-size: 12.4em;
  font-weight: 700;
  color: #f8f8f8;
  animation: ${animation} 2s forwards cubic-bezier(0.2, 0.8, 0.2, 1.0);

  @media only screen and (min-width: 375px) and (max-width: 812px) {
  font-size: 4em;
  font-weight: 750;
  }
`



const BackgroundText = props => (
  <div>
    <Text>{props.text}</Text>
  
  </div>
)

export default BackgroundText

